import themeGet from '@styled-system/theme-get';
import { debounce, isFunction } from 'lodash';
import { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';

import { Box } from '@/components/box';
import { TextInput } from '@/components/inputs';
import { LoadingIndicator } from '@/components/ui/loading/indicator';
import { useFeatureFlag } from '@/hooks/use-feature-flag';
import { AIRPORT, AUTOCOMPLETE_TYPESENSE, MODE_AIR, MODE_WATER, SEAPORT } from '@/lib/constants';
import { OptionItem, SearchEndpointTypes } from '@/types';
import { PlaceInterface } from '@/types/api-types';
import { transformCarrierToOptions } from '@/utils/carrier';
import { createCarrierSearchUrl, createPlacesSearchUrl } from '@/utils/helpers';
import { isArrayOfCarrierInterface, isArrayOfPlaceInterface } from '@/utils/helpers/type-checks';
import { logEvent } from '@/utils/logger';
import { transformPlacesToOptions } from '@/utils/places';
import { cn } from '@/utils/styles';

const getSearchEndpoint = (endpoint: SearchEndpointTypes, searchString: string, engine: string) => {
  switch (endpoint) {
    case 'ports':
      return createPlacesSearchUrl(searchString, [SEAPORT, AIRPORT], engine);
    case 'carriers':
      return createCarrierSearchUrl(searchString, undefined, engine);
    case 'airCarriers':
      return createCarrierSearchUrl(searchString, MODE_AIR, engine);
    case 'oceanCarriers':
      return createCarrierSearchUrl(searchString, MODE_WATER, engine);
    default:
      return endpoint;
  }
};

interface Props {
  readonly size?: 'small' | 'default';
  readonly endpoint: SearchEndpointTypes;
  readonly name?: string;
  readonly placeholder?: string;
  readonly onChange?: (val: string) => void;
  readonly onSearchResponseChange?: (options: OptionItem[]) => void;
}

/**
 * Search Input for querying different endpoints
 */
const SearchInput = ({
  size = 'small',
  name = 'search',
  endpoint,
  placeholder = 'Enter a search term',
  onChange,
  onSearchResponseChange,
}: Props) => {
  const totalFetches = useRef(0);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  const { variant: USE_TYPESENSE_AUTOCOMPLETE } = useFeatureFlag('typesense-autocomplete');
  const searchEngine = USE_TYPESENSE_AUTOCOMPLETE ? AUTOCOMPLETE_TYPESENSE : '';

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isFunction(onChange)) {
      const { value } = e.target;
      // Set internal value
      setSearchValue(value);
      // Publish value
      onChange(value);
      // Run search
      debouncedQueryEndpoint(value);
    }
  };

  const queryEndpoint = async (searchString: string) => {
    totalFetches.current += 1;
    const thisFetch = totalFetches.current;

    setIsSearching(true);

    // If there is a search string, search for it
    if (searchString) {
      try {
        const endpointUrl = getSearchEndpoint(endpoint, searchString, searchEngine);
        const response = await fetch(endpointUrl);
        const data = await response.json();
        // Ensures a previous query never overwrites newer responses
        if (thisFetch === totalFetches.current) {
          handleSearchQueryResponse(data?.results);
        }
        setIsSearching(false);
      } catch (error) {
        setIsSearching(false);
        logEvent.error('Search Query Error', { error });
      }
    } else {
      setIsSearching(false);
      handleSearchQueryResponse([]);
    }
  };

  const debouncedQueryEndpoint = useCallback(debounce(queryEndpoint, 150), []);

  // Handle responses, some need shaping, others are ok as is
  const handleSearchQueryResponse = async (options: PlaceInterface[] | OptionItem[]) => {
    if (isArrayOfPlaceInterface(options) && isFunction(onSearchResponseChange)) {
      // Convert Places to Options
      onSearchResponseChange(transformPlacesToOptions(options));
    } else if (isArrayOfCarrierInterface(options) && isFunction(onSearchResponseChange)) {
      // Convert Carriers to Options
      onSearchResponseChange(transformCarrierToOptions(options));
    } else if (isFunction(onSearchResponseChange)) {
      onSearchResponseChange(options as OptionItem[]);
    }
  };

  return (
    <Box position="relative">
      <InputExtra>{isSearching && <LoadingIndicator />}</InputExtra>
      <TextInput
        size={size}
        label="Search"
        hideLabel
        placeholder={placeholder}
        value={searchValue}
        name={name}
        onChange={onInputChange}
        className={cn('w-full shadow-softer', {
          'max-h-[34px]': size === 'small',
        })}
      />
    </Box>
  );
};

const InputExtra = styled.div`
  width: ${themeGet('space.7')};
  height: ${themeGet('space.7')};
  position: absolute;
  top: 50%;
  right: ${themeGet('space.sm')};
  bottom: 0;
  transform: translateY(-50%);
  display: grid;
  place-items: center;
  z-index: ${themeGet('zIndices.3')};
`;

export { SearchInput };
